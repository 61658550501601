.is-accent {
  color: $accent; }
.is-center {
  text-align: center; }

.block-col-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px; }

.z-index-2 {
  z-index: 2; }
