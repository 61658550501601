.cultures {
  @include flex(flex-start, normal, row, wrap); }

.culture {
  text-align: center;
  padding-top: 45px;
  padding-left: 10px;
  padding-right: 10px;
  height: 280px;
  width: 25%;
  margin-bottom: 85px;
  background-color: #f0f0f0;
  @include flex(flex-start, center, column);
  position: relative;
  overflow: hidden;
  @include tr(background-color .3s);
  @include hover {
    background-color: $accent;
    .culture__title {
      color: $white;
      .h4, h4 {
        color: $white; } } }
  @include before {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: $white;
    z-index: 0;
    width: 120%;
    height: 30%;
    border-top-right-radius: 80%;
    border-top-left-radius: 80%;
    position: absolute; }
  @include md {
    width: 50%;
    height: 230px;
    padding-top: 20px;
    margin-bottom: 50px; }
  @include xs {
    width: 100%; }
  &__img {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    z-index: 1;
    img {} }
  &__title {
    position: relative;
    z-index: 2;
    @include tr(color .3s); } }
