@include font('opensans-regular', 'opensans-regular-webfont');
@include font('opensans-bold', 'opensans-bold-webfont');
@include font('opensans-semibold', 'opensans-semibold-webfont');

@mixin reg {
  font-family: 'opensans-regular', Arial, sans-serif; }
@mixin bold {
  font-family: 'opensans-bold', Arial, sans-serif; }
@mixin semi {
  font-family: 'opensans-semibold', Arial, sans-serif; }

body {
  background: #FFF;
  line-height: 1.4;
  font-size: var(--fz);
  @include reg;
  color: $c-text;
  @include no-scroll; }


.out {
  overflow: hidden; }

img {
  max-width: 100%; }

sup {
  vertical-align: top;
  font-size: .5em; }
sub {
  vertical-align: bottom;
  font-size: .5em; }

.icon {
  display: inline-block; }

.btn {
  display: inline-block;
  text-align: center;
  min-width: 253px;
  padding: 14px;
  border: 1px solid $accent;
  background-color: transparent;
  color: $accent;
  @include reg;
  font-size: var(--fz);
  @include tr(color .3s, background-color .3s);
  @include hover {
    background-color: $accent;
    color: $white;
    @include bold; }
  .icon {
    fill: currentColor;
    font-size: calc(var(--fz) + 2px);
    top: .2em;
    margin-right: 5px;
    position: relative; }
  @include md {
    padding: 10px; }
  @include sm {
    padding: 8px;
    min-width: 200px; }

  &--md {
    min-width: 220px; }
  &--accent {
    background-color: $accent;
    color: $white;
    @include bold;
    @include hover {
      background-color: transparent;
      @include reg;
      color: $accent; } }
  &--full {
    width: 100%; }
  &--white {
    border-color: $white;
    color: $white; } }

.btn-link {
  color: #595959;
  font-size: 13px;
  @include reg;
  text-decoration: underline;
  @include inline-flex(flex-start, center);
  @include tr(color .3s);
  @include hover {
    color: $accent; }
  .icon {
    fill: currentColor;
    font-size: var(--fz);
    margin-right: 5px;
    @include tr(fill .3s); } }

.download {
  color: $white;
  font-size: var(--fz);
  @include reg;
  @include inline-flex(flex-start, center);
  text-decoration: underline;
  @include tr(color .3s);
  .icon {
    margin-right: 14px; } }

.text-block {
  &__title {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__text {
      margin-bottom: 15px; }
  &__btns {
    font-size: 0;
    @include md {
      text-align: center; } }
  &__btn {
    display: inline-block;
    vertical-align: top;
    @include notlast {
      margin-right: 10px; }
    @include md {
      @include notlast {
        margin-bottom: 10px;
        margin-right: 0; } }
    @include sm {
      margin-left: 5px;
      margin-right: 5px;
      @include notlast {
        margin-right: 5px; } } } }

.link-arrow {
  @include link-arrow(var(--fz), #383838, $white); }

.meta-item {
  color: #adadad;
  font-size: 13px;
  @include inline-flex(flex-start, center);
  @include reg;
  .icon {
    fill: currentColor;
    margin-right: 5px; } }

.types {
  &--sm {
    font-size: 0;
    .type {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 0;
      padding-top: 10%;
      @include xs {
        padding-top: 15%; } } } }

.type {
  position: relative;
  display: block;
  padding-top: 45%;
  margin-bottom: 30px;
  @include cover;
  font-size: var(--fz);
  color: $white;
  @include bold;
  @include md {
    margin-bottom: 20px; }
  @include hover {
    font-size: 21px;
    @include before {
      visibility: hidden;
      opacity: 0; } }
  @include before {
    background-color: rgba($accent, .7);
    @include coverdiv;
    z-index: 0;
    @include tr(visibility .3s, opacity .3s); }
  &__title {
    @include vcenter;
    z-index: 1;
    display: block; } }
