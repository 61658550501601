h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.h1, h1 {}

.h2, h2 {
  @include bold;
  color: $black;
  line-height: 1.2;
  @include css-lock(24, 30, 576, 1199, font-size);
  &--white {
    color: white; } }

.h3, h3 {}

.h4, h4 {
  color: $black;
  @include bold;
  @include tr(color .3s);
  @include css-lock(18, 21, 576, 1199, font-size);
  &--white {
    color: $white; } }

.h5, h5 {
  font-size: var(--fz);
  color: $black;
  text-transform: uppercase;
  @include bold;
  &--accent {
    color: $accent; } }

.h6, h6 {}

.text {
  color: $c-text;
  text-align: justify;
  font-size: var(--fz);

  &--center {
    text-align: center; }
  &--white {
    color: $white; }
  &--black {
    color: $black; }
  &--sm {
    font-size: var(--fz-sm); } }

.text-block {
  --margin-bottom: 35px;
  @include clr;
  @include md {
    --margin-bottom: 25px; }
  a:not([class*='btn']) {
    color: $accent;
    text-decoration: underline; }
  ul, ol {
    margin-bottom: var(--margin-bottom); }
  ul {
    list-style-type: disc;
    padding-left: 20px; }
  ol {
    list-style-type: decimal;
    padding-left: 20px; }
  p {
    margin-bottom: var(--margin-bottom); }
  img {
    margin-bottom: 10px;
    margin-top: 10px;
    &.is-right {
      float: right;
      margin-left: 15px;
      @include md {
        max-width: 40%; }
      @include xs {
        float: none;
        width: 100%;
        max-width: 100%; } }
    &.is-left {
      float: left;
      margin-right: 15px;
      @include md {
        max-width: 40%; }
      @include xs {
        float: none;
        width: 100%;
        max-width: 100%; } } }
  .news-meta {
    text-align: right;
    margin-bottom: 10px; } }
