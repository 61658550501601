.contacts-tabs {
  &__tabs {
    font-size: 0; }
  &__tab {
    @extend .btn;
    border: 0;
    text-align: left;
    text-transform: uppercase;
    min-width: 0;
    padding-left: 20px;
    padding-right: 20px;
    @include hover {
      @extend .contacts-tabs__tab.is-active; }
    @include lg {
      padding-left: 10px;
      padding-right: 10px; }
    @include xs {
      display: block;
      text-align: center; }
    &.is-active {
      background-color: $accent;
      color: $white;
      @include bold;
      box-shadow: 0px 5px 13.65px 1.35px rgba(0, 0, 0, 0.11); } }
  &__content {
    padding-top: 25px;
    @include sm {
      padding-top: 15px; }
    @include xs {
      padding-top: 10px; } } }

.contacts-item {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  @include tr(opacity .3s);
  &.is-active {
    visibility: visible;
    max-height: none;
    opacity: 1; }
  &__social {
    margin-bottom: 35px;
    @include md {
      margin-bottom: 30px; }
    @include sm {
      margin-bottom: 15px; } }
  &__text {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 25px; }
    @include sm {
      margin-bottom: 15px; } }
  &__btns {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 15px; }
    @include sm {
      margin-bottom: 15px; } }
  &__btn {
    @include md {
      @include notlast {
        margin-right: 10px;
        margin-bottom: 0; } }
    @include xs {
      @include notlast {
        margin-right: 5px;
        margin-bottom: 10px; } } } }

.contacts-list {
  li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 30px;
    @include md {
      margin-bottom: 25px; }
    @include sm {
      margin-bottom: 15px;
      padding-left: 25px; }
    .icon {
      position: absolute;
      fill: $accent;
      font-size: 18px;
      left: 0;
      top: .1em;
      @include sm {
        font-size: 16px; } } } }


