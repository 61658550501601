.news-item {
  $this: &;
  height: calc(100% - 25px);
  margin-bottom: 25px;
  display: block;
  @include hover {
    #{$this}__bottom {
      background-color: $black;
      .link-arrow {
        color: $white; } }
    #{$this}__top {
      @include before {
        visibility: hidden;
        opacity: 0; } } }
  &__top {
    @include cover;
    background-color: $gray;
    @include flex(flex-end, normal, column);
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-top: 25%;
    height: calc(100% - 85px);
    position: relative;
    @include before {
      @include coverdiv;
      background-color: rgba($black, .5);
      z-index: 0;
      @include tr(opacity .3s, visibility .3s); }
    @include sm {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px; }
    @include xs {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-top: 40%; } }
  &__title {
    margin-bottom: 10px; }
  &__bottom {
    height: 85px;
    padding-left: 40px;
    padding-right: 40px;
    @include flex(space-between, center);
    background-color: $white;
    box-shadow: 0px 4px 17.6px 4.4px rgba(0, 0, 0, 0.11);
    @include tr(background-color .3s);
    @include sm {
      padding-left: 20px;
      padding-right: 20px;
      height: 60px; }
    @include xs {
      padding-left: 10px;
      padding-right: 10px; }
    @include xss {
      display: block;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px; } }
  &__meta {
    @include xss {
      display: block;
      padding-top: 10px; }
    .meta-item {
      @include notlast {
        margin-right: 25px; } } }
  &__block {
    max-width: 360px;
    position: relative;
    z-index: 1; }
  &__text {
    display: block;
    max-height: 80px;
    @include sm {
      max-height: 60px; } } }
