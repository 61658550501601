.nav {
  font-size: 0;
  @include md {
    padding-top: 75px;
    overflow: auto; }
  li {
    display: inline-block;
    @include lg {
      display: block;
      text-align: center; } }
  a {
    display: block;
    font-size: var(--fz);
    @include reg;
    color: $accent;
    @include tr(color .3s, background-color .3s);
    padding: 30px 15px;
    @include lg {
      padding: 10px 10px; }
    &.is-active {
      @include semi;
      background-color: #eaf0ef; }
    @include hover {
      // +semi
      background-color: #eaf0ef; } }

  &--footer {
    @include md {
      padding-top: 0; }
    li {
      display: block;
      @include notlast {
        margin-bottom: 20px; }
      @include lg {
        text-align: left;
        @include notlast {
          margin-bottom: 10px; } } }
    a {
      padding: 0;
      color: $green-light;
      @include hover {
        background-color: transparent;
        text-decoration: underline;
        color: $green; }
      @include lg {
        padding: 0; } } } }

.types-nav {
  @include flex(flex-start, normal, row, wrap);
  &__link {
    position: relative;
    @include cover;
    padding-top: 5%;
    width: 25%;
    display: block;
    @include bold;
    font-size: var(--fz);
    color: $white;
    @include hover {
      font-size: 21px;
      @include before {
        visibility: hidden;
        opacity: 0; } }
    @include before {
      background-color: rgba($accent, .7);
      @include coverdiv;
      z-index: 0;
      @include tr(visibility .3s, opacity .3s); }
    @include lg {
      padding-top: 5%; }
    @include md {
      padding-top: 7%; }
    @include sm {
      padding-top: 8%; }
    @include xs {
      padding-top: 12%;
      width: 50%; }
    span {
      position: relative;
      z-index: 1;
      display: inline-block;
      @include vcenter; } } }
