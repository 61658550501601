.footer {
  background-color: $accent-dark;
  padding-top: 40px;
  padding-bottom: 40px;
  color: $green-light;
  position: relative;
  z-index: 2;
  @include reg;
  @include md {
    padding-bottom: 60px;
    .nav {
      margin-bottom: 15px; }
    [class*="col-"] {
      position: static; }
    .container {
      position: relative; } }
  @include xs {
    padding-top: 20px;
    padding-bottom: 110px; }
  &__title, {
    margin-bottom: 50px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__logo {
    margin-bottom: 35px;
    @include lg {
      margin-bottom: 25px; }
    @include md {
      margin-bottom: 15px; } }
  &__social {
    margin-bottom: 35px;
    @include lg {
      margin-bottom: 25px; }
    @include md {
      position: absolute;
      top: calc(100% + 8px);
      right: 15px; }
    @include xs {
      right: auto;
      left: 15px;
      top: 100%; } }
  &__text-sm {
    font-size: 13px;
    max-width: 160px;
    @include md {
      position: absolute;
      top: 100%;
      left: 15px;
      p {
        white-space: nowrap; } }
    @include xs {
      top: calc(100% + 45px); }
    p {
      margin-bottom: 10px; } }
  &__text {
    font-size: var(--fz);
    p {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } }
    a {
      @include tr(color .3s);
      @include hover {
        text-decoration: underline;
        color: $green; } } } }
