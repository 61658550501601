.section {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  position: relative;
  &__title {
    margin-bottom: 25px;
    text-align: center;
    @include md {
      margin-bottom: 15px; }
    .h2, h2 {
      margin-bottom: 15px;
      @include md {
        margin-bottom: 10px; } } }
  &__title-left {
    text-align: left; }
  &__title-md {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  &__btn {
    text-align: center; }
  &__pagination {
    text-align: center; }
  &__gray-block {
    background-color: $gray;
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding); }
  &__white-block {
    background-color: $white;
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding); }
  &__block {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
    &:nth-child(odd) {
      background-color: $gray; } }
  &__btn-block {
    .text {
      margin-bottom: 15px; } }
  &__types {
    margin-bottom: 40px;
    @include md {
      margin-bottom: 20px; } }


  &--light {
    background-color: $gray-light; }
  &--gray {
    background-color: $gray; }
  &--no-pad {
    padding: 0; }
  &--no-pad-bottom {
    padding-bottom: 0; }
  &--img {
    z-index: 1;
    position: relative;
    .container {
      position: relative;
      z-index: 1; } }
  &--contacts {
    padding-bottom: 12%;
    background-image: radial-gradient(circle, rgba(#fafafa,1) 0%, rgba(#e4e4e4,1) 100%);
    overflow: hidden;
    @include r(1760) {
      padding-bottom: 10%; }
    @include r(1560) {
      padding-bottom: 5%; }
    @include lg {
      padding-bottom: 0; }
    &__top {
      background-image: radial-gradient(circle, rgba(#efefef,1) 0%, rgba(#dddddd,1) 100%); } }
  &--full {
    padding-bottom: 100px;
    .breadcrumbs {
      margin-bottom: 100px; } } }

.section-img {
  position: absolute;
  width: 350px;
  height: 300px;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: contain;

  &--right {
    right: 0;
    bottom: 65px;
    text-align: right;
    background-position: 100% 50%; }
  &--left {
    left: 0;
    bottom: 0;
    @include flex(flex-start, flex-end); }
  &--contacts {
    width: percentage(800/1920);
    height: auto;
    padding-top: 40%;
    background-size: contain;
    top: 50px;
    left: 0;
    @include md {
      display: none; } }
  &--right-lg {
    height: 90%;
    width: 200px; }
  &--right-sm {
    width: 200px;
    height: 200px; }
  &--right-xs {
    width: 100px;
    height: 100px; }

  &--right-center {
    bottom: 7%;
    right: 0;
    z-index: 0;
    background-size: contain;
    background-position: 100% 50%;
    height: auto;
    padding-top: 30%;
    width: 24%;
    @include r(1760) {
      width: 20%; }
    @include r(1560) {
      width: 15%; }
    @include lg {
      display: none; } }
  &--right-bottom {
    bottom: calc(0px - var(--section-padding));
    right: 0;
    background-position: 100% 50%;
    @include md {
      display: none; } }
  &--left-bottom {
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 25%;
    height: auto;
    padding-top: 18%;
    @include md {
      display: none; } }
  &--left-bottom-sm {
    width: 200px;
    height: 200px; }
  &--left-center {
    left: 0;
    top: 50%;
    // transform: translate(0, -50%)
    margin-top: -150px;
    @include lg {
      display: none; } }
  &--left-top {
    top: 0;
    left: 0;
    width: 100px;
    height: 100px; }
  &--full {
    width: 200px;
    height: 200px; }
  &--full-left-1,
  &--full-left-2 {
    left: 0; }

  &--full-left-1 {
    top: 10%; }
  &--full-left-2 {
    top: 30%; }
  &--full-right-1,
  &--full-right-2 {
    right: 0;
    text-align: right;
    background-position: 100% 50%; }
  &--full-right-1 {
    top: 10%; }
  &--full-right-2 {
    top: 40%; }
  &--full-lg {
    height: 400px; }

  &--left-sm {
    width: 200px;
    height: 200px;
    padding-top: 10%; } }
