.social {
  li {
    display: inline-block;
    @include notlast {
      margin-right: 10px; } }
  a {
    @include flex(center, center);
    border-radius: 50%;
    height: 32px;
    width: 32px;
    background-color: $accent;
    @include tr(background-color .3s);
    @include hover {
      background-color: $accent-dark; }
    .icon {
      fill: $white; } }

  &--light {
    a {
      background-color: $accent-light; } } }
