.production {
  --padding: 20px;
  --rect-width: 135px;
  --rect-sm-right: 30px;
  --top-color: #007d4b;
  --logo-color: #f3ffea;
  --rect-wrap-color: #007d4b;
  --rect-color: #015238;
  --rect-sm-color: #026940;
  --col-width-1: 35%;
  --col-width-2: 41%;
  --col-width-3: 24%;
  $this: &;
  @include notlast {
    margin-bottom: 40px; }
  @include lg {
    --padding: 15px;
    --rect-width: 120px;
    font-size: 14px;
    @include notlast {
      margin-bottom: 30px; } }
  @include md {
    --padding: 10px;
    --col-width-1: 50%;
    --col-width-2: 50%;
    --col-width-3: 100%; }
  @include sm {
    --rect-width: 10px;
    --col-width-1: 100%;
    --col-width-2: 100%; }
  &.is-green {
    --top-color: #007d4b;
    --logo-color: #f3ffea;
    --rect-color: #015238;
    --rect-sm-color: #026940;
    --rect-wrap-color: #007d4b; }
  &.is-blue {
    --top-color: #003580;
    --logo-color: #f0f9ff;
    --rect-color: #0072ba;
    --rect-sm-color: #70b5e1;
    --rect-wrap-color: #8fcbf1; }
  &__top {
    @include flex(); }
  &__top-logo {
    width: 220px;
    padding: var(--padding);
    @include flex(center, center);
    background-color: var(--logo-color); }
  &__top-left {
    padding: var(--padding);
    position: relative;
    flex-grow: 1;
    @include flex();
    padding-right: var(--rect-width);
    background-color: var(--top-color); }
  &__items {
    border: 1px solid  $green-light;
    border-top: 0; }

  &__top-rect {
    position: absolute;
    height: 100%;
    width: var(--rect-width);
    top: 0;
    right: 0;
    background-color: var(--rect-wrap-color);
    @include sm {
      display: none; }
    @include before {
      width: var(--rect-width);
      height: 85%;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 1;
      background-color: var(--rect-color); }
    @include after {
      top: 0;
      right: var(--rect-sm-right);
      background-color: var(--rect-sm-color);
      width: calc(var(--rect-width) - var(--rect-sm-right));
      position: absolute;
      height: 15%;
      z-index: 1; } }

  &__top-title {
    color: $white;
    text-transform: uppercase;
    strong {
      @include semi;
      font-size: 30px;
      text-transform: none;
      @include lg {
        font-size: 24px; } }
    sup {
      font-size: .5em;
      display: inline-block;
      vertical-align: top; } }
  &__caption {
    @include flex();
    background-color: $gray-dark;
    border-left: 1px solid  $green-light;
    border-right: 1px solid  $green-light;
    @include md {
      display: none; } }
  &__caption-col {
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    padding-left: var(--padding);
    @include semi;
    text-transform: uppercase;
    color: $black;
    &:nth-child(1) {
      width: var(--col-width-1); }
    &:nth-child(2) {
      width: var(--col-width-2); }
    &:nth-child(3) {
      width: var(--col-width-3); } } }

.production-item {
  @include flex(flex-start, normal, row, wrap);
  background-color: $white;
  &:nth-child(even) {
    background-color: #f0f5f4; }
  &__col {
    padding-left: calc(var(--padding) * 1.5);
    padding-right: calc(var(--padding) * 1.5);
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    @include flex(center, center);
    border-right: 1px solid  $green-light;
    @include sm {
      border-right: 0; }
    &:nth-child(1) {
      width: var(--col-width-1);
      flex-direction: row;
      justify-content: flex-start; }
    &:nth-child(2) {
      width: var(--col-width-2);
      flex-direction: column;
      align-items: flex-start;
      @include md {
        border-right: 0; } }
    &:nth-child(3) {
      width: var(--col-width-3);
      border-right: 0;
      @include md {
        justify-content: flex-start; } } }
  &__formula {
    width: 100%; }
  &__img {
    margin-right: 30px;
    @include md {
      margin-right: 15px; } }
  &__title {
    color: $accent;
    @include bold;
    margin-bottom: 5px;
    sup {
      font-size: .5em;
      vertical-align: top; }
    sub {
      vertical-align: bottom;
      font-size: .5em; } }
  &__text {
    margin-bottom: 20px;
    max-height: 80px; } }

.production-icons {
  font-size: 0;
  li {
    display: inline-block;
    margin-bottom: 5px;
    @include notlast {
      margin-right: 1px; } }
  a {
    @include flex(center, center);
    background-color: #e5f6ee;
    color: $accent;
    font-size: 25px;
    padding: 8px;
    width: 38px;
    height: 38px;
    @include tr(color .3s, background-color .3s);
    @include hover {
      color: $white;
      background-color: $accent; }
    .icon {
      fill: currentColor; } }

  &--static {
    li {
      @include inline-flex(center, center);
      font-size: 25px;
      padding: 8px;
      width: 38px;
      height: 38px;
      background-color: transparent;
      border: 1px solid #227162;
      color: #77d7c4;
      .icon {
        fill: currentColor; } } }

  &--transp {
    a {
      background-color: transparent;
      border: 1px solid  $accent; } } }

.production-formula {
  sub {
    font-size: .5em;
    display: inline-block;
    vertical-align: bottom; }
  dl {
    @include clr; }
  dt {
    @include bold;
    color: $black; }
  dt, dd {
    float: left;
    width: 25%;
    margin-bottom: 15px;
    @include md {
      width: 10%; }
    @include sm {
      width: 12.5%;
 }      // margin-right: 5px
    @include xs {
      width: 25%; } }
  dt {
    @include md {
      // width: 30%
      // width: auto
 } }      // margin-right: 4px
  dd {
    white-space: nowrap;
    @include md {
      // width: 70%
      // width: auto
 } } }      // margin-right: 4px


.product {
  --product-margin: 25px;
  @include sm {
    --product-margin: 15px; }
  &__top-right {
    background-color: $gray;
    height: 100%;
    padding: var(--product-margin);
    padding-top: calc(var(--product-margin) / 2);
    position: relative;
    @include md {
      margin-top: 50px;
      height: auto; } }
  &__logo {
    position: absolute;
    bottom: 100%;
    right: 0;
    background-color: $green-lighten;
    padding: 5px 25px; }
  &__btns {
    margin-bottom: var(--product-margin); }
  &__btn {
    @include lg {
      @include notlast {
        margin-bottom: 10px; } }
    @include md {
      @include notlast {
        margin-bottom: 0;
        margin-right: 10px; } }
    @include sm {
      @include notlast {
        margin-bottom: 10px; } } }
  &__meta {
    margin-bottom: var(--product-margin); }
  &__item {
    @include cover;
    background-size: 100% 90%;
    background-position: 50% 100%;
    padding-bottom: 5px;
    height: 100%;
    .item {
      max-width: 385px;
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      margin-bottom: 0;
      &:hover {
        .item__top {
          color: $accent;
          background-color: $white;
          border-bottom-color: $gray-dark; } }
      &__middle {
        // padding-top: 0
        flex-grow: 1;
        @include flex(flex-end, center, column);
        padding-bottom: 12px;
        @include md {
          padding-top: 70%; } }
      &__middle-icons {
 } } }        // text-align: center
  &__descr-title {
    margin-bottom: calc(var(--product-margin) / 2); }
  &__bottom {
    padding-top: var(--product-margin);
    p {
      margin-bottom: var(--product-margin);
      strong {
        @include reg;
        color: $black; } } } }


.product-meta {}
