html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

* { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -moz-tap-highlight-color: rgba(0, 0, 0, 0); }

a { text-decoration: none; color: inherit; }

:root { --section-padding: 30px; --fz:              16px; --fz-sm:           14px; }

@media only screen and (max-width: 991px) { :root { --section-padding: 20px; } }

@media only screen and (max-width: 767px) { :root { --fz: 14px; } }

/*! Bootstrap Grid v4.3.1 (https://getbootstrap.com/) Copyright 2011-2019 The Bootstrap Authors Copyright 2011-2019 Twitter, Inc. Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE) */
html { box-sizing: border-box; -ms-overflow-style: scrollbar; }

*, *::before, *::after { box-sizing: inherit; }

.container, .slider__arrows { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

@media (min-width: 576px) { .container, .slider__arrows { max-width: 540px; } }

@media (min-width: 768px) { .container, .slider__arrows { max-width: 720px; } }

@media (min-width: 992px) { .container, .slider__arrows { max-width: 960px; } }

@media (min-width: 1200px) { .container, .slider__arrows { max-width: 1200px; } }

.container-fluid { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }

.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }

.col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }

.col-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }

.col-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }

.col-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.order-first { order: -1; }

.order-last { order: 13; }

.order-0 { order: 0; }

.order-1 { order: 1; }

.order-2 { order: 2; }

.order-3 { order: 3; }

.order-4 { order: 4; }

.order-5 { order: 5; }

.order-6 { order: 6; }

.order-7 { order: 7; }

.order-8 { order: 8; }

.order-9 { order: 9; }

.order-10 { order: 10; }

.order-11 { order: 11; }

.order-12 { order: 12; }

.offset-1 { margin-left: 8.33333%; }

.offset-2 { margin-left: 16.66667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.33333%; }

.offset-5 { margin-left: 41.66667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.33333%; }

.offset-8 { margin-left: 66.66667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.33333%; }

.offset-11 { margin-left: 91.66667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-sm-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-sm-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-sm-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.66667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.33333%; }
  .offset-sm-5 { margin-left: 41.66667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.33333%; }
  .offset-sm-8 { margin-left: 66.66667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.33333%; }
  .offset-sm-11 { margin-left: 91.66667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.66667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.33333%; }
  .offset-md-5 { margin-left: 41.66667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.33333%; }
  .offset-md-8 { margin-left: 66.66667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.33333%; }
  .offset-md-11 { margin-left: 91.66667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-lg-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-lg-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-lg-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.66667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.33333%; }
  .offset-lg-5 { margin-left: 41.66667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.33333%; }
  .offset-lg-8 { margin-left: 66.66667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.33333%; }
  .offset-lg-11 { margin-left: 91.66667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.66667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.33333%; }
  .offset-xl-5 { margin-left: 41.66667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.33333%; }
  .offset-xl-8 { margin-left: 66.66667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.33333%; }
  .offset-xl-11 { margin-left: 91.66667%; } }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

.flatpickr-calendar { background: transparent; opacity: 0; display: none; text-align: center; visibility: hidden; padding: 0; -webkit-animation: none; animation: none; direction: ltr; border: 0; font-size: 14px; line-height: 24px; border-radius: 5px; position: absolute; width: 307.875px; -webkit-box-sizing: border-box; box-sizing: border-box; -ms-touch-action: manipulation; touch-action: manipulation; background: #fff; -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open, .flatpickr-calendar.inline { opacity: 1; max-height: 640px; visibility: visible; }

.flatpickr-calendar.open { display: inline-block; z-index: 99999; }

.flatpickr-calendar.animate.open { -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline { display: block; position: relative; top: 2px; }

.flatpickr-calendar.static { position: absolute; top: calc(100% + 2px); }

.flatpickr-calendar.static.open { z-index: 999; display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) { -webkit-box-shadow: none !important; box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) { -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer { border-bottom: 0; border-bottom-right-radius: 0; border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer { border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time { height: 40px; border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time { height: auto; }

.flatpickr-calendar:before, .flatpickr-calendar:after { position: absolute; display: block; pointer-events: none; border: solid transparent; content: ''; height: 0; width: 0; left: 22px; }

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after { left: auto; right: 22px; }

.flatpickr-calendar:before { border-width: 5px; margin: 0 -5px; }

.flatpickr-calendar:after { border-width: 4px; margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after { bottom: 100%; }

.flatpickr-calendar.arrowTop:before { border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after { border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after { top: 100%; }

.flatpickr-calendar.arrowBottom:before { border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after { border-top-color: #fff; }

.flatpickr-calendar:focus { outline: 0; }

.flatpickr-wrapper { position: relative; display: inline-block; }

.flatpickr-months { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; }

.flatpickr-months .flatpickr-month { background: transparent; color: rgba(0, 0, 0, 0.9); fill: rgba(0, 0, 0, 0.9); height: 28px; line-height: 1; text-align: center; position: relative; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; overflow: hidden; -webkit-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1; }

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month { text-decoration: none; cursor: pointer; position: absolute; top: 0px; line-height: 16px; height: 28px; padding: 10px; z-index: 3; color: rgba(0, 0, 0, 0.9); fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled { display: none; }

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i { position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month { /* /*rtl:begin:ignore*/ /* */ left: 0; /* /*rtl:end:ignore*/ /* */ }

/* /*rtl:begin:ignore*/
/* /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month { /* /*rtl:begin:ignore*/ /* */ right: 0; /* /*rtl:end:ignore*/ /* */ }

/* /*rtl:begin:ignore*/
/* /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover { color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg { fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg { width: 14px; height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path { -webkit-transition: fill 0.1s; transition: fill 0.1s; fill: inherit; }

.numInputWrapper { position: relative; height: auto; }

.numInputWrapper input, .numInputWrapper span { display: inline-block; }

.numInputWrapper input { width: 100%; }

.numInputWrapper input::-ms-clear { display: none; }

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button { margin: 0; -webkit-appearance: none; }

.numInputWrapper span { position: absolute; right: 0; width: 14px; padding: 0 4px 0 2px; height: 50%; line-height: 50%; opacity: 0; cursor: pointer; border: 1px solid rgba(57, 57, 57, 0.15); -webkit-box-sizing: border-box; box-sizing: border-box; }

.numInputWrapper span:hover { background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active { background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after { display: block; content: ""; position: absolute; }

.numInputWrapper span.arrowUp { top: 0; border-bottom: 0; }

.numInputWrapper span.arrowUp:after { border-left: 4px solid transparent; border-right: 4px solid transparent; border-bottom: 4px solid rgba(57, 57, 57, 0.6); top: 26%; }

.numInputWrapper span.arrowDown { top: 50%; }

.numInputWrapper span.arrowDown:after { border-left: 4px solid transparent; border-right: 4px solid transparent; border-top: 4px solid rgba(57, 57, 57, 0.6); top: 40%; }

.numInputWrapper span svg { width: inherit; height: auto; }

.numInputWrapper span svg path { fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover { background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span { opacity: 1; }

.flatpickr-current-month { font-size: 135%; line-height: inherit; font-weight: 300; color: inherit; position: absolute; width: 75%; left: 12.5%; padding: 6.16px 0 0 0; line-height: 1; height: 28px; display: inline-block; text-align: center; -webkit-transform: translate3d(0px, 0px, 0px); transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month { font-family: inherit; font-weight: 700; color: inherit; display: inline-block; margin-left: 0.5ch; padding: 0; }

.flatpickr-current-month span.cur-month:hover { background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper { width: 6ch; width: 7ch\0; display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after { border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after { border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year { background: transparent; -webkit-box-sizing: border-box; box-sizing: border-box; color: inherit; cursor: text; padding: 0 0 0 0.5ch; margin: 0; display: inline-block; font-size: inherit; font-family: inherit; font-weight: 300; line-height: inherit; height: auto; border: 0; border-radius: 0; vertical-align: initial; -webkit-appearance: textfield; -moz-appearance: textfield; appearance: textfield; }

.flatpickr-current-month input.cur-year:focus { outline: 0; }

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover { font-size: 100%; color: rgba(0, 0, 0, 0.5); background: transparent; pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months { appearance: menulist; background: transparent; border: none; border-radius: 0; box-sizing: border-box; color: inherit; cursor: pointer; font-size: inherit; font-family: inherit; font-weight: 300; height: 22px; line-height: inherit; margin: -1px 0 0 0; outline: none; padding: 0 0 0 0.5ch; position: relative; vertical-align: initial; -webkit-box-sizing: border-box; -webkit-appearance: menulist; -moz-appearance: menulist; width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active { outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover { background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month { background-color: transparent; outline: none; padding: 0; }

.flatpickr-weekdays { background: transparent; text-align: center; overflow: hidden; width: 100%; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1; }

span.flatpickr-weekday { cursor: default; font-size: 90%; background: transparent; color: rgba(0, 0, 0, 0.54); line-height: 1; margin: 0; text-align: center; display: block; -webkit-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1; font-weight: bolder; }

.dayContainer, .flatpickr-weeks { padding: 1px 0 0 0; }

.flatpickr-days { position: relative; overflow: hidden; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: start; -webkit-align-items: flex-start; -ms-flex-align: start; align-items: flex-start; width: 307.875px; }

.flatpickr-days:focus { outline: 0; }

.dayContainer { padding: 0; outline: 0; text-align: left; width: 307.875px; min-width: 307.875px; max-width: 307.875px; -webkit-box-sizing: border-box; box-sizing: border-box; display: inline-block; display: -ms-flexbox; display: -webkit-box; display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap; -ms-flex-pack: justify; -webkit-justify-content: space-around; justify-content: space-around; -webkit-transform: translate3d(0px, 0px, 0px); transform: translate3d(0px, 0px, 0px); opacity: 1; }

.dayContainer + .dayContainer { -webkit-box-shadow: -1px 0 0 #e6e6e6; box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day { background: none; border: 1px solid transparent; border-radius: 150px; -webkit-box-sizing: border-box; box-sizing: border-box; color: #393939; cursor: pointer; font-weight: 400; width: 14.2857143%; -webkit-flex-basis: 14.2857143%; -ms-flex-preferred-size: 14.2857143%; flex-basis: 14.2857143%; max-width: 39px; height: 39px; line-height: 39px; margin: 0; display: inline-block; position: relative; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; text-align: center; }

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus { cursor: pointer; outline: 0; background: #e6e6e6; border-color: #e6e6e6; }

.flatpickr-day.today { border-color: #959ea9; }

.flatpickr-day.today:hover, .flatpickr-day.today:focus { border-color: #959ea9; background: #959ea9; color: #fff; }

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay { background: #569ff7; -webkit-box-shadow: none; box-shadow: none; color: #fff; border-color: #569ff7; }

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange { border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange { border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) { -webkit-box-shadow: -10px 0 0 #569ff7; box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange { border-radius: 50px; }

.flatpickr-day.inRange { border-radius: 0; -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay { color: rgba(57, 57, 57, 0.3); background: transparent; border-color: transparent; cursor: default; }

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover { cursor: not-allowed; color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected { border-radius: 0; -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-day.hidden { visibility: hidden; }

.rangeMode .flatpickr-day { margin-top: 1px; }

.flatpickr-weekwrapper { float: left; }

.flatpickr-weekwrapper .flatpickr-weeks { padding: 0 12px; -webkit-box-shadow: 1px 0 0 #e6e6e6; box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday { float: none; width: 100%; line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover { display: block; width: 100%; max-width: none; color: rgba(57, 57, 57, 0.3); background: transparent; cursor: default; border: none; }

.flatpickr-innerContainer { display: block; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; }

.flatpickr-rContainer { display: inline-block; padding: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }

.flatpickr-time { text-align: center; outline: 0; display: block; height: 0; line-height: 40px; max-height: 40px; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; }

.flatpickr-time:after { content: ""; display: table; clear: both; }

.flatpickr-time .numInputWrapper { -webkit-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1; width: 40%; height: 40px; float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after { border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after { border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper { width: 26%; }

.flatpickr-time.time24hr .numInputWrapper { width: 49%; }

.flatpickr-time input { background: transparent; -webkit-box-shadow: none; box-shadow: none; border: 0; border-radius: 0; text-align: center; margin: 0; padding: 0; height: inherit; line-height: inherit; color: #393939; font-size: 14px; position: relative; -webkit-box-sizing: border-box; box-sizing: border-box; -webkit-appearance: textfield; -moz-appearance: textfield; appearance: textfield; }

.flatpickr-time input.flatpickr-hour { font-weight: bold; }

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second { font-weight: 400; }

.flatpickr-time input:focus { outline: 0; border: 0; }

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm { height: inherit; float: left; line-height: inherit; color: #393939; font-weight: bold; width: 2%; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-align-self: center; -ms-flex-item-align: center; align-self: center; }

.flatpickr-time .flatpickr-am-pm { outline: 0; width: 18%; cursor: pointer; text-align: center; font-weight: 400; }

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus { background: #eee; }

.flatpickr-input[readonly] { cursor: pointer; }

@-webkit-keyframes fpFadeInDown { from { opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown { from { opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to { opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@font-face { font-family: "opensans-regular"; src: url("../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans-regular-webfont.woff") format("woff"); font-weight: normal; font-style: normal; font-display: swap; }

@font-face { font-family: "opensans-bold"; src: url("../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../fonts/opensans-bold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; font-display: swap; }

@font-face { font-family: "opensans-semibold"; src: url("../fonts/opensans-semibold-webfont.woff2") format("woff2"), url("../fonts/opensans-semibold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; font-display: swap; }

body { background: #FFF; line-height: 1.4; font-size: var(--fz); font-family: 'opensans-regular', Arial, sans-serif; color: #7b7b7b; }

body.no-scroll { overflow: hidden; }

.no-touch body.no-scroll { margin-right: 17px; }

.out { overflow: hidden; }

img { max-width: 100%; }

sup { vertical-align: top; font-size: .5em; }

sub { vertical-align: bottom; font-size: .5em; }

.icon { display: inline-block; }

.btn, .contacts-tabs__tab { display: inline-block; text-align: center; min-width: 253px; padding: 14px; border: 1px solid #005948; background-color: transparent; color: #005948; font-family: 'opensans-regular', Arial, sans-serif; font-size: var(--fz); transition: color 0.3s, background-color 0.3s; }

.no-touch .btn:hover, .no-touch .contacts-tabs__tab:hover { background-color: #005948; color: #fff; font-family: 'opensans-bold', Arial, sans-serif; }

.btn .icon, .contacts-tabs__tab .icon { fill: currentColor; font-size: calc(var(--fz) + 2px); top: .2em; margin-right: 5px; position: relative; }

@media only screen and (max-width: 991px) { .btn, .contacts-tabs__tab { padding: 10px; } }

@media only screen and (max-width: 767px) { .btn, .contacts-tabs__tab { padding: 8px; min-width: 200px; } }

.btn--md { min-width: 220px; }

.btn--accent { background-color: #005948; color: #fff; font-family: 'opensans-bold', Arial, sans-serif; }

.no-touch .btn--accent:hover { background-color: transparent; font-family: 'opensans-regular', Arial, sans-serif; color: #005948; }

.btn--full { width: 100%; }

.btn--white { border-color: #fff; color: #fff; }

.btn-link { color: #595959; font-size: 13px; font-family: 'opensans-regular', Arial, sans-serif; text-decoration: underline; display: inline-flex; justify-content: flex-start; align-items: center; transition: color 0.3s; }

.no-touch .btn-link:hover { color: #005948; }

.btn-link .icon { fill: currentColor; font-size: var(--fz); margin-right: 5px; transition: fill 0.3s; }

.download { color: #fff; font-size: var(--fz); font-family: 'opensans-regular', Arial, sans-serif; display: inline-flex; justify-content: flex-start; align-items: center; text-decoration: underline; transition: color 0.3s; }

.download .icon { margin-right: 14px; }

.text-block__title { margin-bottom: 15px; }

@media only screen and (max-width: 991px) { .text-block__title { margin-bottom: 10px; } }

.text-block__text { margin-bottom: 15px; }

.text-block__btns { font-size: 0; }

@media only screen and (max-width: 991px) { .text-block__btns { text-align: center; } }

.text-block__btn { display: inline-block; vertical-align: top; }

.text-block__btn:not(:last-child) { margin-right: 10px; }

@media only screen and (max-width: 991px) { .text-block__btn:not(:last-child) { margin-bottom: 10px; margin-right: 0; } }

@media only screen and (max-width: 767px) { .text-block__btn { margin-left: 5px; margin-right: 5px; }
  .text-block__btn:not(:last-child) { margin-right: 5px; } }

.link-arrow { color: #383838; font-size: var(--fz); display: inline-flex; justify-content: flex-start; align-items: center; font-family: 'opensans-regular', Arial, sans-serif; transition: color 0.3s; }

.no-touch .link-arrow:hover .link-arrow__arrow, .no-touch .link-arrow:hover .pagination .next, .pagination .no-touch .link-arrow:hover .next, .no-touch .link-arrow:hover .pagination .prev, .pagination .no-touch .link-arrow:hover .prev { width: 80px; color: #fff; }

.link-arrow__title { display: block; margin-right: 10px; }

.link-arrow__arrow, .pagination .next, .pagination .prev { position: relative; width: 60px; right: 0; top: 50%; transform: translate(0, -50%); display: block; transition: transform .3s, width .3s; }

.link-arrow__arrow::before, .pagination .next::before, .pagination .prev::before { width: 100%; content: ''; position: absolute; height: 1px; background-color: currentColor; top: 50%; right: -1px; transform: translate(0, -50%); transition: width .3s; }

.link-arrow__arrow::after, .pagination .next::after, .pagination .prev::after { display: inline-block; position: absolute; width: 8px; height: 8px; border-left: 1px solid currentColor; border-bottom: 1px solid currentColor; transform: rotate(225deg); content: ''; top: 50%; transform: translate(0, -50%) rotate(225deg) !important; right: 0; }

.meta-item { color: #adadad; font-size: 13px; display: inline-flex; justify-content: flex-start; align-items: center; font-family: 'opensans-regular', Arial, sans-serif; }

.meta-item .icon { fill: currentColor; margin-right: 5px; }

.types--sm { font-size: 0; }

.types--sm .type { width: 50%; display: inline-block; vertical-align: top; margin-bottom: 0; padding-top: 10%; }

@media only screen and (max-width: 575px) { .types--sm .type { padding-top: 15%; } }

.type { position: relative; display: block; padding-top: 45%; margin-bottom: 30px; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; font-size: var(--fz); color: #fff; font-family: 'opensans-bold', Arial, sans-serif; }

@media only screen and (max-width: 991px) { .type { margin-bottom: 20px; } }

.no-touch .type:hover { font-size: 21px; }

.no-touch .type:hover::before { content: ''; visibility: hidden; opacity: 0; }

.type::before { content: ''; background-color: rgba(0, 89, 72, 0.7); position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0; transition: visibility 0.3s, opacity 0.3s; }

.type__title { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; display: block; }

h1, h2, h3, h4, h5, h6 { font-weight: 400; }

.h2, h2 { font-family: 'opensans-bold', Arial, sans-serif; color: #2f2f2f; line-height: 1.2; font-size: 30px; font-size: calc(24px + (30 - 24) * ((100vw - 576px) / (1199 - 576))); }

@media (max-width: 576px) { .h2, h2 { font-size: 24px; } }

@media (min-width: 1199px) { .h2, h2 { font-size: 30px; } }

.h2--white, h2--white { color: white; }

.h4, .feature--icon .feature__title, h4 { color: #2f2f2f; font-family: 'opensans-bold', Arial, sans-serif; transition: color 0.3s; font-size: 21px; font-size: calc(18px + (21 - 18) * ((100vw - 576px) / (1199 - 576))); }

@media (max-width: 576px) { .h4, .feature--icon .feature__title, h4 { font-size: 18px; } }

@media (min-width: 1199px) { .h4, .feature--icon .feature__title, h4 { font-size: 21px; } }

.h4--white, h4--white { color: #fff; }

.h5, h5 { font-size: var(--fz); color: #2f2f2f; text-transform: uppercase; font-family: 'opensans-bold', Arial, sans-serif; }

.h5--accent, h5--accent { color: #005948; }

.text { color: #7b7b7b; text-align: justify; font-size: var(--fz); }

.text--center { text-align: center; }

.text--white { color: #fff; }

.text--black { color: #2f2f2f; }

.text--sm { font-size: var(--fz-sm); }

.text-block { --margin-bottom: 35px; }

.text-block:after { content: " "; display: table; clear: both; }

@media only screen and (max-width: 991px) { .text-block { --margin-bottom: 25px; } }

.text-block a:not([class*='btn']) { color: #005948; text-decoration: underline; }

.text-block ul, .text-block ol { margin-bottom: var(--margin-bottom); }

.text-block ul { list-style-type: disc; padding-left: 20px; }

.text-block ol { list-style-type: decimal; padding-left: 20px; }

.text-block p { margin-bottom: var(--margin-bottom); }

.text-block img { margin-bottom: 10px; margin-top: 10px; }

.text-block img.is-right { float: right; margin-left: 15px; }

@media only screen and (max-width: 991px) { .text-block img.is-right { max-width: 40%; } }

@media only screen and (max-width: 575px) { .text-block img.is-right { float: none; width: 100%; max-width: 100%; } }

.text-block img.is-left { float: left; margin-right: 15px; }

@media only screen and (max-width: 991px) { .text-block img.is-left { max-width: 40%; } }

@media only screen and (max-width: 575px) { .text-block img.is-left { float: none; width: 100%; max-width: 100%; } }

.text-block .news-meta { text-align: right; margin-bottom: 10px; }

.header { position: relative; z-index: 10; }

.header.has-open-menu .header__bottom { position: relative; transform: translate(0, 0); z-index: 1; }

.header.has-open-menu .header__contacts { z-index: 10; transform: translate(0, 0); }

.header__top { background-color: #fff; }

.header__top .header__inner { height: 82px; }

@media only screen and (max-width: 991px) { .header__top .header__inner { height: 50px; } }

.header__bottom { background-color: #005948; }

@media only screen and (max-width: 991px) { .header__bottom { position: absolute; transform: translate(0, -100%); z-index: -1; width: 100%; }
  .header__bottom .header__inner { height: auto; display: block; text-align: center; } }

.header__bottom .header__inner { height: 48px; padding-top: 8px; padding-bottom: 8px; }

.header__inner { display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: nowrap; }

.header__title { font-size: var(--fz); text-transform: uppercase; color: #fff; font-family: 'opensans-regular', Arial, sans-serif; }

@media only screen and (max-width: 991px) { .header__title { margin-bottom: 10px; } }

.header__btns { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: nowrap; }

.header__btns .social { margin-left: 40px; }

@media only screen and (max-width: 991px) { .header__btns { justify-content: center; } }

@media only screen and (max-width: 575px) { .header__btns { display: block; }
  .header__btns .social { margin-left: 0; }
  .header__btns .download { margin-bottom: 8px; } }

@media only screen and (max-width: 1199px) { .header__nav { position: absolute; top: 100%; width: 100%; left: 0; height: 100vh; overflow: auto; background-color: rgba(47, 47, 47, 0.6); z-index: 10; visibility: hidden; opacity: 0; transition: visibility 0.3s, opacity 0.3s; }
  .header__nav.is-active { visibility: visible; opacity: 1; }
  .header__nav.is-active .nav { transform: translate(0, 0); }
  .header__nav .nav { background-color: #fff; transition: transform 0.3s; transform: translate(0, -100%); } }

@media only screen and (max-width: 1199px) { .header__logo { flex-grow: 1; } }

@media only screen and (max-width: 1199px) { .header__contacts { margin-left: 10px; }
  .header__contacts .contact { display: inline-block; }
  .header__contacts .contact:not(:last-child) { margin-right: 5px; } }

@media only screen and (max-width: 991px) { .header__contacts { position: absolute; width: 100%; top: 100%; left: 0; z-index: -1; margin-left: 0; text-align: center; padding-left: 10px; padding-right: 10px; padding-top: 10px; transform: translate(0, -100%); transition: opacity 0.3s, visibility 0.3s, transform 0.3s; }
  .header__contacts .contact { display: block; } }

.header__burger { display: none; }

@media only screen and (max-width: 1199px) { .header__burger { display: block; margin-left: 15px; } }

.contact { font-family: 'opensans-bold', Arial, sans-serif; color: #2f2f2f; display: block; }

.no-touch .contact:hover { text-decoration: underline; }

.logo { display: block; }

.logo .icon { font-size: 35px; fill: currentColor; }

.logo--accent { color: #005948; }

.logo--white { color: #fff; }

.logo--light { color: #00a32a; }

.lang { color: #005948; }

.lang ul { position: relative; }

.lang ul::before { content: ''; width: 6px; height: 6px; border-left: 1px solid currentColor; border-bottom: 1px solid currentColor; transform: rotate(-45deg); display: inline-block; position: absolute; top: 8px; right: 0; }

.lang ul:hover li:last-child { display: block; }

.lang a { text-transform: uppercase; color: #005948; font-size: var(--fz); font-family: 'opensans-regular', Arial, sans-serif; }

.no-touch .lang a:hover { text-decoration: underline; }

.lang li { position: relative; padding-right: 15px; }

.lang li:last-child { position: absolute; top: 100%; left: 0; display: none; }

.burger { display: block; width: 28px; height: 28px; margin-top: 12px; }

.burger span:after, .burger span:before { content: ""; position: absolute; left: 0; top: 9px; }

.burger span:after { top: 18px; }

.burger span { position: relative; display: block; }

.burger span, .burger span:after, .burger span:before { width: 100%; height: 2px; background-color: #005948; transition: all 0.3s; backface-visibility: hidden; border-radius: 2px; }

.burger.is-active span { background-color: transparent; }

.burger.is-active span:before { transform: rotate(45deg) translate(-1px, 0px); }

.burger.is-active span:after { transform: rotate(-45deg) translate(6px, -7px); }

.footer { background-color: #002723; padding-top: 40px; padding-bottom: 40px; color: #aee1dc; position: relative; z-index: 2; font-family: 'opensans-regular', Arial, sans-serif; }

@media only screen and (max-width: 991px) { .footer { padding-bottom: 60px; }
  .footer .nav { margin-bottom: 15px; }
  .footer [class*="col-"] { position: static; }
  .footer .container, .footer .slider__arrows { position: relative; } }

@media only screen and (max-width: 575px) { .footer { padding-top: 20px; padding-bottom: 110px; } }

.footer__title { margin-bottom: 50px; }

@media only screen and (max-width: 1199px) { .footer__title { margin-bottom: 30px; } }

@media only screen and (max-width: 991px) { .footer__title { margin-bottom: 15px; } }

.footer__logo { margin-bottom: 35px; }

@media only screen and (max-width: 1199px) { .footer__logo { margin-bottom: 25px; } }

@media only screen and (max-width: 991px) { .footer__logo { margin-bottom: 15px; } }

.footer__social { margin-bottom: 35px; }

@media only screen and (max-width: 1199px) { .footer__social { margin-bottom: 25px; } }

@media only screen and (max-width: 991px) { .footer__social { position: absolute; top: calc(100% + 8px); right: 15px; } }

@media only screen and (max-width: 575px) { .footer__social { right: auto; left: 15px; top: 100%; } }

.footer__text-sm { font-size: 13px; max-width: 160px; }

@media only screen and (max-width: 991px) { .footer__text-sm { position: absolute; top: 100%; left: 15px; }
  .footer__text-sm p { white-space: nowrap; } }

@media only screen and (max-width: 575px) { .footer__text-sm { top: calc(100% + 45px); } }

.footer__text-sm p { margin-bottom: 10px; }

.footer__text { font-size: var(--fz); }

.footer__text p { margin-bottom: 25px; }

@media only screen and (max-width: 991px) { .footer__text p { margin-bottom: 15px; } }

.footer__text a { transition: color 0.3s; }

.no-touch .footer__text a:hover { text-decoration: underline; color: #00a32a; }

.nav { font-size: 0; }

@media only screen and (max-width: 991px) { .nav { padding-top: 75px; overflow: auto; } }

.nav li { display: inline-block; }

@media only screen and (max-width: 1199px) { .nav li { display: block; text-align: center; } }

.nav a { display: block; font-size: var(--fz); font-family: 'opensans-regular', Arial, sans-serif; color: #005948; transition: color 0.3s, background-color 0.3s; padding: 30px 15px; }

@media only screen and (max-width: 1199px) { .nav a { padding: 10px 10px; } }

.nav a.is-active { font-family: 'opensans-semibold', Arial, sans-serif; background-color: #eaf0ef; }

.no-touch .nav a:hover { background-color: #eaf0ef; }

@media only screen and (max-width: 991px) { .nav--footer { padding-top: 0; } }

.nav--footer li { display: block; }

.nav--footer li:not(:last-child) { margin-bottom: 20px; }

@media only screen and (max-width: 1199px) { .nav--footer li { text-align: left; }
  .nav--footer li:not(:last-child) { margin-bottom: 10px; } }

.nav--footer a { padding: 0; color: #aee1dc; }

.no-touch .nav--footer a:hover { background-color: transparent; text-decoration: underline; color: #00a32a; }

@media only screen and (max-width: 1199px) { .nav--footer a { padding: 0; } }

.types-nav { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: wrap; }

.types-nav__link { position: relative; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; padding-top: 5%; width: 25%; display: block; font-family: 'opensans-bold', Arial, sans-serif; font-size: var(--fz); color: #fff; }

.no-touch .types-nav__link:hover { font-size: 21px; }

.no-touch .types-nav__link:hover::before { content: ''; visibility: hidden; opacity: 0; }

.types-nav__link::before { content: ''; background-color: rgba(0, 89, 72, 0.7); position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0; transition: visibility 0.3s, opacity 0.3s; }

@media only screen and (max-width: 1199px) { .types-nav__link { padding-top: 5%; } }

@media only screen and (max-width: 991px) { .types-nav__link { padding-top: 7%; } }

@media only screen and (max-width: 767px) { .types-nav__link { padding-top: 8%; } }

@media only screen and (max-width: 575px) { .types-nav__link { padding-top: 12%; width: 50%; } }

.types-nav__link span { position: relative; z-index: 1; display: inline-block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.social li { display: inline-block; }

.social li:not(:last-child) { margin-right: 10px; }

.social a { display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; border-radius: 50%; height: 32px; width: 32px; background-color: #005948; transition: background-color 0.3s; }

.no-touch .social a:hover { background-color: #002723; }

.social a .icon { fill: #fff; }

.social--light a { background-color: #036f5a; }

.hero--simple { background-color: #fff; padding-top: var(--section-padding); padding-bottom: var(--section-padding); }

.slide { display: block; }

.slider { font-size: 0; }

.slider__wrap { position: relative; }

.slider__dots { background-color: #005948; padding: 16px 12px 12px; min-width: 220px; text-align: center; font-size: 0; position: absolute; bottom: 0; left: 50%; transform: translate(-50%, 0); }

.slider__dots li { display: inline-block; margin-right: 5px; margin-left: 5px; }

.slider__dots li.slick-active button::before { content: ''; visibility: visible; opacity: 1; }

.slider__dots button { background-color: #fff; font: 0/0 a; text-shadow: none; color: transparent; height: 10px; width: 10px; border-radius: 50%; position: relative; }

.slider__dots button::before { content: ''; z-index: 0; height: 18px; width: 18px; display: inline-block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 50%; background-color: rgba(255, 255, 255, 0.15); visibility: hidden; opacity: 0; transition: opacity 0.3s, visibility 0.3s; }

.slider__prev, .slider__next { position: absolute; top: 50%; transform: translate(0, -50%); height: 30px; width: 30px; color: #fff; border: 2px solid currentColor; border-radius: 50%; }

.slider__prev::before, .slider__next::before { content: ''; display: inline-block; position: absolute; top: 50%; }

.slider__arrows { position: absolute; top: 50%; transform: translate(-50%, -50%); left: 50%; }

.slider__prev { left: 0; }

.slider__prev::before { content: ''; width: 8px; height: 8px; border-left: 2px solid currentColor; border-bottom: 2px solid currentColor; transform: rotate(45deg); transform: translate(0, -50%) rotate(45deg); left: 10px; }

.slider__next { right: 0; }

.slider__next::before { content: ''; width: 8px; height: 8px; border-left: 2px solid currentColor; border-bottom: 2px solid currentColor; transform: rotate(-135deg); transform: translate(0, -50%) rotate(-135deg); right: 10px; }

.slider--banner { background-color: #7b7b7b; }

.slider--banner .slide__img { display: block; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; width: 100%; background-color: #7b7b7b; }

.slider--about__wrap .slider__dots { background-color: transparent; min-width: 0; width: 100%; }

.slider--about__wrap .slider__dots button { height: 34px; width: 15px; border-radius: 0; background-color: transparent; }

.slider--about__wrap .slider__dots button::before { content: ''; display: none; }

.slider--about__wrap .slider__dots button::after { content: ''; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); height: 100%; width: 2px; background-color: #9b9b9b; display: block; }

.slider--about__wrap .slider__dots li { margin-right: 2px; margin-left: 2px; }

.slider--about__wrap .slider__dots li.slick-active button::after { content: ''; height: calc(100% + 14px); background-color: #005948; }

.slider--partners .slide { height: 80px; }

.slider--partners .slide__img { display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; height: 100%; }

.no-touch .slider--partners .slide__img:hover img { filter: grayscale(0); }

.slider--partners .slide__img img { filter: grayscale(100%); transition: filter 0.3s; }

.slider--more__wrap { --more-arrows-gap: -25px; }

.slider--more__wrap .slick-track { margin-left: -15px; margin-right: -15px; padding-left: 15px; padding-right: 15px; }

.slider--more__wrap .slick-slide { margin-left: 15px; margin-right: 15px; }

@media only screen and (max-width: 1199px) { .slider--more__wrap .item__cover-text { max-height: 50px; } }

@media only screen and (max-width: 767px) { .slider--more__wrap .item__cover-text { margin-bottom: 10px; } }

.slider--more__wrap .slider__prev, .slider--more__wrap .slider__next { background-color: transparent; color: #005948; }

@media only screen and (max-width: 575px) { .slider--more__wrap .slider__prev, .slider--more__wrap .slider__next { display: none !important; } }

.slider--more__wrap .slider__prev { left: var(--more-arrows-gap); }

.slider--more__wrap .slider__next { right: var(--more-arrows-gap); }

.slider--more__wrap .slider__dots { display: none; position: relative; background-color: transparent; }

.slider--more__wrap .slider__dots button { background-color: #005948; }

.slider--more__wrap .slider__dots button::before { content: ''; background-color: rgba(0, 89, 72, 0.15); }

@media only screen and (max-width: 575px) { .slider--more__wrap .slider__dots { display: block; } }

.section { padding-top: var(--section-padding); padding-bottom: var(--section-padding); position: relative; }

.section__title { margin-bottom: 25px; text-align: center; }

@media only screen and (max-width: 991px) { .section__title { margin-bottom: 15px; } }

.section__title .h2, .section__title h2 { margin-bottom: 15px; }

@media only screen and (max-width: 991px) { .section__title .h2, .section__title h2 { margin-bottom: 10px; } }

.section__title-left { text-align: left; }

.section__title-md { max-width: 800px; margin-left: auto; margin-right: auto; }

.section__btn { text-align: center; }

.section__pagination { text-align: center; }

.section__gray-block { background-color: #fbfbfb; padding-top: var(--section-padding); padding-bottom: var(--section-padding); }

.section__white-block { background-color: #fff; padding-top: var(--section-padding); padding-bottom: var(--section-padding); }

.section__block { padding-top: var(--section-padding); padding-bottom: var(--section-padding); }

.section__block:nth-child(odd) { background-color: #fbfbfb; }

.section__btn-block .text { margin-bottom: 15px; }

.section__types { margin-bottom: 40px; }

@media only screen and (max-width: 991px) { .section__types { margin-bottom: 20px; } }

.section--light { background-color: #f9f9f9; }

.section--gray { background-color: #fbfbfb; }

.section--no-pad { padding: 0; }

.section--no-pad-bottom { padding-bottom: 0; }

.section--img { z-index: 1; position: relative; }

.section--img .container, .section--img .slider__arrows { position: relative; z-index: 1; }

.section--contacts { padding-bottom: 12%; background-image: radial-gradient(circle, #fafafa 0%, #e4e4e4 100%); overflow: hidden; }

@media only screen and (max-width: 1760px) { .section--contacts { padding-bottom: 10%; } }

@media only screen and (max-width: 1560px) { .section--contacts { padding-bottom: 5%; } }

@media only screen and (max-width: 1199px) { .section--contacts { padding-bottom: 0; } }

.section--contacts__top { background-image: radial-gradient(circle, #efefef 0%, #dddddd 100%); }

.section--full { padding-bottom: 100px; }

.section--full .breadcrumbs { margin-bottom: 100px; }

.section-img { position: absolute; width: 350px; height: 300px; z-index: 0; background-repeat: no-repeat; background-size: contain; }

.section-img--right { right: 0; bottom: 65px; text-align: right; background-position: 100% 50%; }

.section-img--left { left: 0; bottom: 0; display: flex; justify-content: flex-start; align-items: flex-end; flex-direction: row; flex-wrap: nowrap; }

.section-img--contacts { width: 41.66667%; height: auto; padding-top: 40%; background-size: contain; top: 50px; left: 0; }

@media only screen and (max-width: 991px) { .section-img--contacts { display: none; } }

.section-img--right-lg { height: 90%; width: 200px; }

.section-img--right-sm { width: 200px; height: 200px; }

.section-img--right-xs { width: 100px; height: 100px; }

.section-img--right-center { bottom: 7%; right: 0; z-index: 0; background-size: contain; background-position: 100% 50%; height: auto; padding-top: 30%; width: 24%; }

@media only screen and (max-width: 1760px) { .section-img--right-center { width: 20%; } }

@media only screen and (max-width: 1560px) { .section-img--right-center { width: 15%; } }

@media only screen and (max-width: 1199px) { .section-img--right-center { display: none; } }

.section-img--right-bottom { bottom: calc(0px - var(--section-padding)); right: 0; background-position: 100% 50%; }

@media only screen and (max-width: 991px) { .section-img--right-bottom { display: none; } }

.section-img--left-bottom { left: 0; bottom: 0; z-index: 1; width: 25%; height: auto; padding-top: 18%; }

@media only screen and (max-width: 991px) { .section-img--left-bottom { display: none; } }

.section-img--left-bottom-sm { width: 200px; height: 200px; }

.section-img--left-center { left: 0; top: 50%; margin-top: -150px; }

@media only screen and (max-width: 1199px) { .section-img--left-center { display: none; } }

.section-img--left-top { top: 0; left: 0; width: 100px; height: 100px; }

.section-img--full { width: 200px; height: 200px; }

.section-img--full-left-1, .section-img--full-left-2 { left: 0; }

.section-img--full-left-1 { top: 10%; }

.section-img--full-left-2 { top: 30%; }

.section-img--full-right-1, .section-img--full-right-2 { right: 0; text-align: right; background-position: 100% 50%; }

.section-img--full-right-1 { top: 10%; }

.section-img--full-right-2 { top: 40%; }

.section-img--full-lg { height: 400px; }

.section-img--left-sm { width: 200px; height: 200px; padding-top: 10%; }

.cultures { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: wrap; }

.culture { text-align: center; padding-top: 45px; padding-left: 10px; padding-right: 10px; height: 280px; width: 25%; margin-bottom: 85px; background-color: #f0f0f0; display: flex; justify-content: flex-start; align-items: center; flex-direction: column; flex-wrap: nowrap; position: relative; overflow: hidden; transition: background-color 0.3s; }

.no-touch .culture:hover { background-color: #005948; }

.no-touch .culture:hover .culture__title { color: #fff; }

.no-touch .culture:hover .culture__title .h4, .no-touch .culture:hover .culture__title .feature--icon .feature__title, .feature--icon .no-touch .culture:hover .culture__title .feature__title, .no-touch .culture:hover .culture__title h4 { color: #fff; }

.culture::before { content: ''; bottom: 0; left: 50%; transform: translate(-50%, 0); background-color: #fff; z-index: 0; width: 120%; height: 30%; border-top-right-radius: 80%; border-top-left-radius: 80%; position: absolute; }

@media only screen and (max-width: 991px) { .culture { width: 50%; height: 230px; padding-top: 20px; margin-bottom: 50px; } }

@media only screen and (max-width: 575px) { .culture { width: 100%; } }

.culture__img { position: absolute; bottom: 15px; left: 50%; transform: translate(-50%, 0); width: 100%; z-index: 1; }

.culture__title { position: relative; z-index: 2; transition: color 0.3s; }

.news-item { height: calc(100% - 25px); margin-bottom: 25px; display: block; }

.no-touch .news-item:hover .news-item__bottom { background-color: #2f2f2f; }

.no-touch .news-item:hover .news-item__bottom .link-arrow { color: #fff; }

.no-touch .news-item:hover .news-item__top::before { content: ''; visibility: hidden; opacity: 0; }

.news-item__top { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; background-color: #fbfbfb; display: flex; justify-content: flex-end; align-items: normal; flex-direction: column; flex-wrap: nowrap; padding-left: 40px; padding-right: 40px; padding-bottom: 40px; padding-top: 25%; height: calc(100% - 85px); position: relative; }

.news-item__top::before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(47, 47, 47, 0.5); z-index: 0; transition: opacity 0.3s, visibility 0.3s; }

@media only screen and (max-width: 767px) { .news-item__top { padding-left: 20px; padding-right: 20px; padding-bottom: 20px; } }

@media only screen and (max-width: 575px) { .news-item__top { padding-left: 10px; padding-right: 10px; padding-bottom: 10px; padding-top: 40%; } }

.news-item__title { margin-bottom: 10px; }

.news-item__bottom { height: 85px; padding-left: 40px; padding-right: 40px; display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: nowrap; background-color: #fff; box-shadow: 0px 4px 17.6px 4.4px rgba(0, 0, 0, 0.11); transition: background-color 0.3s; }

@media only screen and (max-width: 767px) { .news-item__bottom { padding-left: 20px; padding-right: 20px; height: 60px; } }

@media only screen and (max-width: 575px) { .news-item__bottom { padding-left: 10px; padding-right: 10px; } }

@media only screen and (max-width: 479px) { .news-item__bottom { display: block; height: auto; padding-top: 10px; padding-bottom: 10px; } }

@media only screen and (max-width: 479px) { .news-item__meta { display: block; padding-top: 10px; } }

.news-item__meta .meta-item:not(:last-child) { margin-right: 25px; }

.news-item__block { max-width: 360px; position: relative; z-index: 1; }

.news-item__text { display: block; max-height: 80px; }

@media only screen and (max-width: 767px) { .news-item__text { max-height: 60px; } }

.breadcrumbs { text-align: left; }

.breadcrumbs li { display: inline-block; }

.breadcrumbs a { font-size: 13px; }

.breadcrumbs .is-active { text-decoration: underline; pointer-events: none; }

.contacts-tabs__tabs { font-size: 0; }

.contacts-tabs__tab { border: 0; text-align: left; text-transform: uppercase; min-width: 0; padding-left: 20px; padding-right: 20px; }

@media only screen and (max-width: 1199px) { .contacts-tabs__tab { padding-left: 10px; padding-right: 10px; } }

@media only screen and (max-width: 575px) { .contacts-tabs__tab { display: block; text-align: center; } }

.contacts-tabs__tab.is-active, .no-touch .contacts-tabs__tab:hover { background-color: #005948; color: #fff; font-family: 'opensans-bold', Arial, sans-serif; box-shadow: 0px 5px 13.65px 1.35px rgba(0, 0, 0, 0.11); }

.contacts-tabs__content { padding-top: 25px; }

@media only screen and (max-width: 767px) { .contacts-tabs__content { padding-top: 15px; } }

@media only screen and (max-width: 575px) { .contacts-tabs__content { padding-top: 10px; } }

.contacts-item { max-height: 0; overflow: hidden; opacity: 0; transition: opacity 0.3s; }

.contacts-item.is-active { visibility: visible; max-height: none; opacity: 1; }

.contacts-item__social { margin-bottom: 35px; }

@media only screen and (max-width: 991px) { .contacts-item__social { margin-bottom: 30px; } }

@media only screen and (max-width: 767px) { .contacts-item__social { margin-bottom: 15px; } }

.contacts-item__text { margin-bottom: 30px; }

@media only screen and (max-width: 991px) { .contacts-item__text { margin-bottom: 25px; } }

@media only screen and (max-width: 767px) { .contacts-item__text { margin-bottom: 15px; } }

.contacts-item__btns { margin-bottom: 20px; }

@media only screen and (max-width: 991px) { .contacts-item__btns { margin-bottom: 15px; } }

@media only screen and (max-width: 767px) { .contacts-item__btns { margin-bottom: 15px; } }

@media only screen and (max-width: 991px) { .contacts-item__btn:not(:last-child) { margin-right: 10px; margin-bottom: 0; } }

@media only screen and (max-width: 575px) { .contacts-item__btn:not(:last-child) { margin-right: 5px; margin-bottom: 10px; } }

.contacts-list li { position: relative; padding-left: 35px; margin-bottom: 30px; }

@media only screen and (max-width: 991px) { .contacts-list li { margin-bottom: 25px; } }

@media only screen and (max-width: 767px) { .contacts-list li { margin-bottom: 15px; padding-left: 25px; } }

.contacts-list li .icon { position: absolute; fill: #005948; font-size: 18px; left: 0; top: .1em; }

@media only screen and (max-width: 767px) { .contacts-list li .icon { font-size: 16px; } }

.main { padding-top: var(--section-padding); padding-bottom: var(--section-padding); }

.pagination { font-size: 0; }

.pagination .next, .pagination .prev { --arrow-width: 60px; width: var(--arrow-width); position: absolute; left: 0; top: 50%; transform: translate(0, -50%); border: 0; min-width: 0; right: auto; }

@media only screen and (max-width: 575px) { .pagination .next, .pagination .prev { --arrow-width: 30px; } }

.pagination .next::before, .pagination .prev::before { content: ''; right: auto; left: 0; }

.pagination .next::after, .pagination .prev::after { content: ''; transition: left 0.3s; right: auto; left: calc(var(--arrow-width) - 8px); }

.no-touch .pagination .next:hover, .no-touch .pagination .prev:hover { background-color: transparent; color: #005948; }

.no-touch .pagination .next:hover::before, .no-touch .pagination .prev:hover::before { content: ''; width: calc(var(--arrow-width) + 20px); }

.no-touch .pagination .next:hover::after, .no-touch .pagination .prev:hover::after { content: ''; left: calc(var(--arrow-width) + 12px); }

.pagination .prev { transform: translate(0, -50%) rotate(180deg); left: auto; right: 0; }

.pagination li { display: inline-block; vertical-align: middle; margin-right: 5px; margin-left: 5px; font-size: 13px; position: relative; }

.pagination a, .pagination span { display: block; border-radius: 35px; color: #005948; background-color: transparent; border: 1px solid #005948; text-align: center; padding: 7px; height: 35px; min-width: 35px; transition: color 0.3s, background-color 0.3s; }

.no-touch .pagination a:hover, .no-touch .pagination span:hover { background-color: #005948; color: #fff; }

.pagination a.is-active, .pagination span.is-active { background-color: #005948; color: #fff; pointer-events: none; }

.pagination a.is-inactive, .pagination span.is-inactive { pointer-events: none; border: 0; background-color: transparent; padding-left: 0; padding-right: 0; min-width: 0; display: inline-flex; justify-content: flex-start; align-items: flex-end; }

.feature { background-color: #eaf0ef; display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: nowrap; text-align: center; padding: 70px 25px; color: #005948; }

@media only screen and (max-width: 991px) { .feature { margin-bottom: 15px; } }

.feature__title { font-size: 70px; font-size: calc(40px + (70 - 40) * ((100vw - 576px) / (1199 - 576))); font-family: 'opensans-bold', Arial, sans-serif; }

@media (max-width: 576px) { .feature__title { font-size: 40px; } }

@media (min-width: 1199px) { .feature__title { font-size: 70px; } }

.feature__icon { fill: currentColor; font-size: 45px; }

.feature--icon { background-color: transparent; padding-top: 0; padding-bottom: 0; }

.feature--icon .feature__title { color: #005948; }

.feature--icon .feature__text { margin-bottom: 8px; }

.partner { display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: wrap; }

@media only screen and (max-width: 575px) { .partner { align-items: flex-start; } }

@media only screen and (max-width: 479px) { .partner { display: block; } }

.partner__img { width: 41.66667%; text-align: center; }

.partner__img .btn, .partner__img .contacts-tabs__tab { margin-top: 15px; }

@media only screen and (max-width: 1199px) { .partner__img { padding-right: 15px; } }

@media only screen and (max-width: 575px) { .partner__img { width: 25%; padding-right: 5px; } }

@media only screen and (max-width: 479px) { .partner__img { width: auto; padding-right: 0; } }

.partner__content { width: 50%; }

@media only screen and (max-width: 1199px) { .partner__content { width: 58.33333%; padding-left: 15px; } }

@media only screen and (max-width: 575px) { .partner__content { width: 75%; padding-left: 5px; } }

@media only screen and (max-width: 479px) { .partner__content { width: auto; padding-left: 0; } }

.partner__text, .partner__logo { margin-bottom: 20px; }

.partner__text p:not(:last-child) { margin-bottom: 30px; }

@media only screen and (max-width: 767px) { .partner__text p:not(:last-child) { margin-bottom: 15px; } }

.partner--lg { align-items: flex-start; }

@media only screen and (max-width: 1199px) { .partner--lg { display: block; }
  .partner--lg:after { content: " "; display: table; clear: both; } }

@media only screen and (max-width: 991px) { .partner--lg .partner__img { float: left; margin-bottom: 15px; } }

@media only screen and (max-width: 767px) { .partner--lg .partner__img { width: auto; float: none; } }

@media only screen and (max-width: 1199px) { .partner--lg .partner__content { width: auto; } }

.production { --padding: 20px; --rect-width: 135px; --rect-sm-right: 30px; --top-color: #007d4b; --logo-color: #f3ffea; --rect-wrap-color: #007d4b; --rect-color: #015238; --rect-sm-color: #026940; --col-width-1: 35%; --col-width-2: 41%; --col-width-3: 24%; }

.production:not(:last-child) { margin-bottom: 40px; }

@media only screen and (max-width: 1199px) { .production { --padding: 15px; --rect-width: 120px; font-size: 14px; }
  .production:not(:last-child) { margin-bottom: 30px; } }

@media only screen and (max-width: 991px) { .production { --padding: 10px; --col-width-1: 50%; --col-width-2: 50%; --col-width-3: 100%; } }

@media only screen and (max-width: 767px) { .production { --rect-width: 10px; --col-width-1: 100%; --col-width-2: 100%; } }

.production.is-green { --top-color: #007d4b; --logo-color: #f3ffea; --rect-color: #015238; --rect-sm-color: #026940; --rect-wrap-color: #007d4b; }

.production.is-blue { --top-color: #003580; --logo-color: #f0f9ff; --rect-color: #0072ba; --rect-sm-color: #70b5e1; --rect-wrap-color: #8fcbf1; }

.production__top { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: nowrap; }

.production__top-logo { width: 220px; padding: var(--padding); display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; background-color: var(--logo-color); }

.production__top-left { padding: var(--padding); position: relative; flex-grow: 1; display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: nowrap; padding-right: var(--rect-width); background-color: var(--top-color); }

.production__items { border: 1px solid #aee1dc; border-top: 0; }

.production__top-rect { position: absolute; height: 100%; width: var(--rect-width); top: 0; right: 0; background-color: var(--rect-wrap-color); }

@media only screen and (max-width: 767px) { .production__top-rect { display: none; } }

.production__top-rect::before { content: ''; width: var(--rect-width); height: 85%; bottom: 0; right: 0; position: absolute; z-index: 1; background-color: var(--rect-color); }

.production__top-rect::after { content: ''; top: 0; right: var(--rect-sm-right); background-color: var(--rect-sm-color); width: calc(var(--rect-width) - var(--rect-sm-right)); position: absolute; height: 15%; z-index: 1; }

.production__top-title { color: #fff; text-transform: uppercase; }

.production__top-title strong { font-family: 'opensans-semibold', Arial, sans-serif; font-size: 30px; text-transform: none; }

@media only screen and (max-width: 1199px) { .production__top-title strong { font-size: 24px; } }

.production__top-title sup { font-size: .5em; display: inline-block; vertical-align: top; }

.production__caption { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: nowrap; background-color: #f0f5f4; border-left: 1px solid #aee1dc; border-right: 1px solid #aee1dc; }

@media only screen and (max-width: 991px) { .production__caption { display: none; } }

.production__caption-col { padding-top: var(--padding); padding-bottom: var(--padding); padding-left: var(--padding); font-family: 'opensans-semibold', Arial, sans-serif; text-transform: uppercase; color: #2f2f2f; }

.production__caption-col:nth-child(1) { width: var(--col-width-1); }

.production__caption-col:nth-child(2) { width: var(--col-width-2); }

.production__caption-col:nth-child(3) { width: var(--col-width-3); }

.production-item { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: wrap; background-color: #fff; }

.production-item:nth-child(even) { background-color: #f0f5f4; }

.production-item__col { padding-left: calc(var(--padding) * 1.5); padding-right: calc(var(--padding) * 1.5); padding-top: var(--padding); padding-bottom: var(--padding); display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; border-right: 1px solid #aee1dc; }

@media only screen and (max-width: 767px) { .production-item__col { border-right: 0; } }

.production-item__col:nth-child(1) { width: var(--col-width-1); flex-direction: row; justify-content: flex-start; }

.production-item__col:nth-child(2) { width: var(--col-width-2); flex-direction: column; align-items: flex-start; }

@media only screen and (max-width: 991px) { .production-item__col:nth-child(2) { border-right: 0; } }

.production-item__col:nth-child(3) { width: var(--col-width-3); border-right: 0; }

@media only screen and (max-width: 991px) { .production-item__col:nth-child(3) { justify-content: flex-start; } }

.production-item__formula { width: 100%; }

.production-item__img { margin-right: 30px; }

@media only screen and (max-width: 991px) { .production-item__img { margin-right: 15px; } }

.production-item__title { color: #005948; font-family: 'opensans-bold', Arial, sans-serif; margin-bottom: 5px; }

.production-item__title sup { font-size: .5em; vertical-align: top; }

.production-item__title sub { vertical-align: bottom; font-size: .5em; }

.production-item__text { margin-bottom: 20px; max-height: 80px; }

.production-icons { font-size: 0; }

.production-icons li { display: inline-block; margin-bottom: 5px; }

.production-icons li:not(:last-child) { margin-right: 1px; }

.production-icons a { display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; background-color: #e5f6ee; color: #005948; font-size: 25px; padding: 8px; width: 38px; height: 38px; transition: color 0.3s, background-color 0.3s; }

.no-touch .production-icons a:hover { color: #fff; background-color: #005948; }

.production-icons a .icon { fill: currentColor; }

.production-icons--static li { display: inline-flex; justify-content: center; align-items: center; font-size: 25px; padding: 8px; width: 38px; height: 38px; background-color: transparent; border: 1px solid #227162; color: #77d7c4; }

.production-icons--static li .icon { fill: currentColor; }

.production-icons--transp a { background-color: transparent; border: 1px solid #005948; }

.production-formula sub { font-size: .5em; display: inline-block; vertical-align: bottom; }

.production-formula dl:after { content: " "; display: table; clear: both; }

.production-formula dt { font-family: 'opensans-bold', Arial, sans-serif; color: #2f2f2f; }

.production-formula dt, .production-formula dd { float: left; width: 25%; margin-bottom: 15px; }

@media only screen and (max-width: 991px) { .production-formula dt, .production-formula dd { width: 10%; } }

@media only screen and (max-width: 767px) { .production-formula dt, .production-formula dd { width: 12.5%; } }

@media only screen and (max-width: 575px) { .production-formula dt, .production-formula dd { width: 25%; } }

.production-formula dd { white-space: nowrap; }

.product { --product-margin: 25px; }

@media only screen and (max-width: 767px) { .product { --product-margin: 15px; } }

.product__top-right { background-color: #fbfbfb; height: 100%; padding: var(--product-margin); padding-top: calc(var(--product-margin) / 2); position: relative; }

@media only screen and (max-width: 991px) { .product__top-right { margin-top: 50px; height: auto; } }

.product__logo { position: absolute; bottom: 100%; right: 0; background-color: #eaf0ef; padding: 5px 25px; }

.product__btns { margin-bottom: var(--product-margin); }

@media only screen and (max-width: 1199px) { .product__btn:not(:last-child) { margin-bottom: 10px; } }

@media only screen and (max-width: 991px) { .product__btn:not(:last-child) { margin-bottom: 0; margin-right: 10px; } }

@media only screen and (max-width: 767px) { .product__btn:not(:last-child) { margin-bottom: 10px; } }

.product__meta { margin-bottom: var(--product-margin); }

.product__item { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; background-size: 100% 90%; background-position: 50% 100%; padding-bottom: 5px; height: 100%; }

.product__item .item { max-width: 385px; margin-left: auto; margin-right: auto; height: 100%; margin-bottom: 0; }

.product__item .item:hover .item__top { color: #005948; background-color: #fff; border-bottom-color: #f0f5f4; }

.product__item .item__middle { flex-grow: 1; display: flex; justify-content: flex-end; align-items: center; flex-direction: column; flex-wrap: nowrap; padding-bottom: 12px; }

@media only screen and (max-width: 991px) { .product__item .item__middle { padding-top: 70%; } }

.product__descr-title { margin-bottom: calc(var(--product-margin) / 2); }

.product__bottom { padding-top: var(--product-margin); }

.product__bottom p { margin-bottom: var(--product-margin); }

.product__bottom p strong { font-family: 'opensans-regular', Arial, sans-serif; color: #2f2f2f; }

.item { --top-bottom-height: 50px; display: flex; justify-content: space-between; align-items: center; flex-direction: column; flex-wrap: nowrap; background-color: #fff; box-shadow: 0px 4px 17.6px 4.4px rgba(0, 0, 0, 0.04); position: relative; margin-bottom: 30px; }

@media only screen and (max-width: 991px) { .item { --top-bottom-height: 45px; } }

@media only screen and (max-width: 991px) { .item { margin-bottom: 15px; } }

.item:hover .item__top { color: #fff; background-color: #005948; border-bottom-color: #fff; }

.item:hover .item__cover { opacity: 1; z-index: 1; }

.item__top { border-bottom: 1px solid #f0f5f4; width: 100%; text-align: center; padding-top: 5px; padding-bottom: 5px; text-transform: uppercase; color: #005948; font-size: var(--fz); display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; font-family: 'opensans-bold', Arial, sans-serif; height: var(--top-bottom-height); transition: color 0.3s, background-color 0.3s, border-color 0.3s; }

@media only screen and (max-width: 991px) { .item__top { font-size: 14px; } }

.item__middle { display: block; padding-top: 80%; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; background-size: auto; width: 100%; }

@media only screen and (max-width: 991px) { .item__middle { padding-top: 100%; } }

@media only screen and (max-width: 575px) { .item__middle { padding-top: 65%; } }

.item__bottom { text-align: center; width: 100%; padding-top: 5px; padding-bottom: 5px; background-color: #005948; color: #fff; font-size: 14px; font-family: 'opensans-bold', Arial, sans-serif; height: var(--top-bottom-height); display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; }

.item__cover { position: absolute; left: 0; width: 100%; font-size: var(--fz); top: var(--top-bottom-height); bottom: var(--top-bottom-height); background-color: rgba(0, 89, 72, 0.95); color: #fff; display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: nowrap; padding: 15px 20px; z-index: -1; opacity: 0; transition: visibility 0.3s, opacity 0.3s; }

.item__cover-title { display: block; font-family: 'opensans-bold', Arial, sans-serif; }

.item__cover-text { margin-bottom: 35px; display: block; max-height: 100px; }

.item__cover-icons { display: block; }

.popup { position: fixed; height: 100%; width: 100%; top: 0; left: 0; z-index: 15; background-color: rgba(0, 0, 0, 0.85); text-align: center; font-size: 0; visibility: hidden; opacity: 0; transition: visibility 0.3s, opacity 0.3s; }

.popup:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }

.popup.is-active { visibility: visible; opacity: 1; }

.popup.is-active .popup__inner { transform: scale(1); }

.popup__inner { display: inline-block; vertical-align: middle; font-size: var(--fz); background-color: #fff; position: relative; max-width: 335px; padding-bottom: 40px; transition: transform 0.3s; transform: scale(0); }

.popup__close { display: inline-block; top: 15px; right: 15px; position: relative; display: inline-block; width: 15px; height: 15px; position: absolute; }

.popup__close::before, .popup__close::after { position: absolute; content: ''; width: 1px; height: 100%; background-color: #2f2f2f; top: 0; left: 50%; }

.popup__close::before { transform: rotate(45deg); }

.popup__close::after { transform: rotate(-45deg); }

.popup__logo { margin-bottom: 30px; background-color: #f0f5f4; padding-top: 25px; padding-bottom: 25px; border-bottom-right-radius: 50%; border-bottom-left-radius: 50%; }

.popup__form { padding-left: 25px; padding-right: 25px; }

.form__field { margin-bottom: 25px; }

.form__btn { padding-top: 15px; }

.form__title { margin-bottom: 25px; }

.input { position: relative; }

.input input { font-family: inherit; font-size: var(--fz-sm); color: #7b7b7b; width: 100%; background-color: #fff; border-bottom: 1px solid #f0f5f4; height: 30px; padding: 5px 0; padding-right: 20px; }

.input input:-ms-input-placeholder { font-family: inherit; color: inherit; }

.input input::placeholder { font-family: inherit; color: inherit; }

.input .icon { fill: currentColor; position: absolute; top: .4em; right: 0; }

.input .icon-call-stroke { fill: none; stroke: currentColor; stroke-width: 2px; }

.is-accent { color: #005948; }

.is-center { text-align: center; }

.block-col-8 { margin-left: auto; margin-right: auto; max-width: 700px; }

.z-index-2 { z-index: 2; }
