$c-text: #7b7b7b;

$accent: #005948;
$accent-dark: #002723;
$accent-light: #036f5a;

$white: #fff;
$black: #2f2f2f;

$green: #00a32a;
$green-light: #aee1dc;
$green-lighten: #eaf0ef;

$gray-light: #f9f9f9;
$gray: #fbfbfb;
$gray-dark: #f0f5f4;

$hd-top: 82px;
$hd-top-mob: 50px;
$hd-bottom: 48px;
$hd-bottom-mob: 30px;

 :root {
  --section-padding: 30px;
  --fz:              16px;
  --fz-sm:           14px; }

@include md {
   :root {
    --section-padding: 20px; } }

@include sm {
   :root {
    --fz: 14px; } }
