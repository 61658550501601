.item {
  --top-bottom-height: 50px;
  @include md {
    --top-bottom-height: 45px; }
  $this: &;
  @include flex(space-between, center, column);
  background-color: $white;
  box-shadow: 0px 4px 17.6px 4.4px rgba(0, 0, 0, 0.04);
  position: relative;
  margin-bottom: 30px;
  @include md {
    margin-bottom: 15px; }
  &:hover {
    #{$this}__top {
      color: $white;
      background-color: $accent;
      border-bottom-color: $white; }
    #{$this}__cover {
      opacity: 1;
      z-index: 1; } }
  &__top {
    border-bottom: 1px solid  $gray-dark;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    color: $accent;
    font-size: var(--fz);
    // display: block
    @include flex(center, center);
    @include bold;
    height: var(--top-bottom-height);
    @include tr(color .3s, background-color .3s, border-color .3s);
    @include md {
      font-size: 14px; } }
  &__middle {
    display: block;
    padding-top: 80%;
    @include cover;
    background-size: auto;
    width: 100%;
    @include md {
      padding-top: 100%; }
    @include xs {
      padding-top: 65%; } }
  &__bottom {
    // display: block
    text-align: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $accent;
    color: $white;
    font-size: 14px;
    @include bold;
    height: var(--top-bottom-height);
    @include flex(center, center); }
  &__cover {
    position: absolute;
    left: 0;
    width: 100%;
    font-size: var(--fz);
    top: var(--top-bottom-height);
    bottom: var(--top-bottom-height);
    background-color: rgba($accent, .95);
    color: $white;
    @include flex(center, center, column);
    padding: 15px 20px;
    // visibility: hidden
    z-index: -1;
    opacity: 0;
    @include tr(visibility .3s, opacity .3s); }
  &__cover-title {
    display: block;
    @include bold; }
  &__cover-text {
    margin-bottom: 35px;
    display: block;
    max-height: 100px; }
  &__cover-icons {
    display: block; } }
