.partner {
  $this: &;
  @include flex(space-between, center, row, wrap);
  @include xs {
    align-items: flex-start; }
  @include xss {
    display: block; }
  &__img {
    width: percentage(5/12);
    text-align: center;
    .btn {
      margin-top: 15px; }
    @include lg {
      padding-right: 15px; }
    @include xs {
      width: percentage(3/12);
      padding-right: 5px; }
    @include xss {
      width: auto;
      padding-right: 0; } }
  &__content {
    width: percentage(6/12);
    @include lg {
      width: percentage(7/12);
      padding-left: 15px; }
    @include xs {
      width: percentage(9/12);
      padding-left: 5px; }
    @include xss {
      width: auto;
      padding-left: 0; } }
  &__text,
  &__logo {
    margin-bottom: 20px; }
  &__text {
    p {
      @include notlast {
        margin-bottom: 30px;
        @include sm {
          margin-bottom: 15px; } } } }

  &--lg {
    align-items: flex-start;
    @include lg {
      display: block;
      @include clr; }
    #{$this}__img {
      @include md {
        float: left;
        margin-bottom: 15px; }
      @include sm {
        width: auto;
        float: none; } }
    #{$this}__content {
      @include lg {
        width: auto; } } } }
