.header {
  $this: &;
  position: relative;
  z-index: 10;
  &.has-open-menu {
    #{$this}__bottom {
      position: relative;
      transform: translate(0, 0);
      z-index: 1; }
    #{$this}__contacts {
      z-index: 10;
      transform: translate(0, 0); } }
  &__top {
    background-color: $white;
    #{$this}__inner {
      height: $hd-top;
      @include md {
        height: $hd-top-mob; } } }
  &__bottom {
    background-color: $accent;
    // +tr(transform .3s)
    @include md {
      position: absolute;
      transform: translate(0, -100%);
      z-index: -1;
      width: 100%;
      #{$this}__inner {
        height: auto;
        display: block;
        text-align: center; } }
    #{$this}__inner {
      height: $hd-bottom;
      padding-top: 8px;
      padding-bottom: 8px; } }

  &__inner {
    @include flex(space-between, center); }
  &__title {
    font-size: var(--fz);
    text-transform: uppercase;
    color: $white;
    @include reg;
    @include md {
      margin-bottom: 10px; } }
  &__btns {
    @include flex();
    .social {
      margin-left: 40px; }
    @include md {
      justify-content: center; }
    @include xs {
      display: block;
      .social {
        margin-left: 0; }
      .download {
        margin-bottom: 8px; } } }
  &__nav {
    @include lg {
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      // height: calc(100vh - #{$hd-top} - #{$hd-bottom})
      height: 100vh;
      overflow: auto;
      background-color: rgba($black, .6);
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      @include tr(visibility .3s, opacity .3s);
      &.is-active {
        visibility: visible;
        opacity: 1;
        .nav {
          transform: translate(0, 0); } }
      .nav {
        background-color: $white;
        @include tr(transform .3s);
        transform: translate(0, -100%); } }
    @include md {
 } }      // height: calc(100vh - #{$hd-top-mob})

  &__logo {
    @include lg {
      flex-grow: 1; } }
  &__contacts {
    @include lg {
      margin-left: 10px;
      .contact {
        display: inline-block;
        @include notlast {
          margin-right: 5px; } } }
    @include md {
      // display: none
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: -1;
      margin-left: 0;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      transform: translate(0, -100%);
      @include tr(opacity .3s, visibility .3s, transform .3s);
      .contact {
        display: block; } } }
  &__burger {
    display: none;
    @include lg {
      display: block;
      margin-left: 15px; } } }

.contacts {}

.contact {
  @include bold;
  color: $black;
  display: block;
  @include hover {
    text-decoration: underline; } }

.logo {
  display: block;
  .icon {
    font-size: 35px;
    fill: currentColor; }
  &--accent {
    color: $accent; }
  &--white {
    color: $white; }
  &--light {
    color: $green; } }

.lang {
  color: $accent;
  ul {
    position: relative;
    @include before {
      @include icon-arrow(6, 6, 1, currentColor, -45);
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 0; }
    &:hover {
      li {
        &:last-child {
          display: block; } } } }
  a {
    text-transform: uppercase;
    color: $accent;
    font-size: var(--fz);
    @include reg;
    @include hover {
      text-decoration: underline; } }
  li {
    position: relative;
    padding-right: 15px;
    &:last-child {
      position: absolute;
      top: 100%;
      left: 0;
      display: none; } } }


.burger {
  display: block;
  width: 28px;
  height: 28px;
  margin-top: 12px;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px; }
  span:after {
    top: 18px; }
  span {
    position: relative;
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: $accent;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }
  &.is-active span {
    background-color: transparent; }
  &.is-active span:before {
    transform: rotate(45deg) translate(-1px, 0px); }
  &.is-active span:after {
    transform: rotate(-45deg) translate(6px, -7px); } }
