.breadcrumbs {
  text-align: left;
  li {
    display: inline-block; }
  a {
    font-size: 13px; }
  .is-active {
    text-decoration: underline;
    pointer-events: none; } }

