.slide {
  display: block; }

.slider {
  $this: &;
  font-size: 0;
  &__wrap {
    position: relative; }
  &__dots {
    background-color: $accent;
    padding: 16px 12px 12px;
    min-width: 220px;
    text-align: center;
    font-size: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    li {
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
      &.slick-active {
        button {
          @include before {
            visibility: visible;
            opacity: 1; } } } }
    button {
      background-color: $white;
      @include hidetext;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      position: relative;
      @include before {
        z-index: 0;
        height: 18px;
        width: 18px;
        display: inline-block;
        @include vcenter;
        border-radius: 50%;
        background-color: rgba($white, .15);
        visibility: hidden;
        opacity: 0;
        @include tr(opacity .3s, visibility .3s); } } }
  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    height: 30px;
    width: 30px;
    color: $white;
    border: 2px solid currentColor;
    border-radius: 50%;
    @include before {
      display: inline-block;
      position: absolute;
      top: 50%; } }
  &__arrows {
    @extend .container;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%; }

  &__prev {
    left: 0;
    @include before {
      @include icon-arrow(8, 8, 2, currentColor, 45);
      transform: translate(0, -50%) rotate(45deg);
      left: 10px; } }
  &__next {
    right: 0;
    @include before {
      @include icon-arrow(8, 8, 2, currentColor, -135);
      transform: translate(0, -50%) rotate(-135deg);
      right: 10px; } }


  &--banner {
    background-color: $c-text;
    .slide {
      &__img {
        display: block;
        @include cover;
        // height: calc(100vh - #{$hd-top} - #{$hd-bottom})
        width: 100%;
        background-color: $c-text;
        @include md {
 } } }          // height: calc(100vh - #{$hd-top-mob})
    &__wrap {
      .slider {
        &__dots {} } } }

  &--about {
    .slide {
      &__img {} }

    &__wrap {
      .slider {
        &__dots {
          background-color: transparent;
          min-width: 0;
          width: 100%;
          button {
            height: 34px;
            width: 15px;
            border-radius: 0;
            background-color: transparent;
            @include before {
              display: none; }
            @include after {
              @include vcenter;
              height: 100%;
              width: 2px;
              background-color: #9b9b9b;
              display: block; } }
          li {
            margin-right: 2px;
            margin-left: 2px;
            &.slick-active {
              button {
                @include after {
                  height: calc(100% + 14px);
                  background-color: $accent; } } } } } } } }
  &--partners {
    .slide {
      height: 80px;
      &__img {
        @include flex(center, center);
        height: 100%;
        @include hover {
          img {
            filter: grayscale(0); } }
        img {
          filter: grayscale(100%);
          @include tr(filter .3s); } } } }

  &--more {

    &__wrap {
      --more-arrows-gap: -25px;
      .slick-track {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px; }
      .slick-slide {
        margin-left: 15px;
        margin-right: 15px; }
      .item {
        &__cover-text {
          @include lg {
            max-height: 50px; }
          @include sm {
            margin-bottom: 10px; } } }
      #{$this}__prev,
      #{$this}__next {
        background-color: transparent;
        color: $accent;
        @include xs {
          display: none !important; } }
      #{$this}__prev {
        left: var(--more-arrows-gap); }
      #{$this}__next {
        right: var(--more-arrows-gap); }
      #{$this}__dots {
        display: none;
        position: relative;
        background-color: transparent;
        button {
          background-color: $accent;
          @include before {
            background-color: rgba($accent, .15); } }
        @include xs {
          display: block; } } } } }


